body{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: Arial, Helvetica, sans-serif;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

#root{
  height: 100%;
}

.container {
  max-width: 1080px;
}

.pricing-header {
  color: white;
}

.pricing-header-country{
  color: #000000;
  padding-top: 1em;
}

.card-deck .card {
  min-width: 220px;
}

.shadow-sm-card{
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75)!important;;
  -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75)!important;;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75)!important;;
}

.shadow-sm-card:hover{
  -webkit-box-shadow: 10px 10px 5px 0px rgba(28,159,230,1)!important;;
  -moz-box-shadow: 10px 10px 5px 0px rgba(28,159,230,1)!important;;
  box-shadow: 10px 10px 5px 0px rgba(28,159,230,1)!important;;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
