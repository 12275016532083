.header-div {
    height: 6em;
}

.menu_div {
    position: absolute;
    width: 40em;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0.5em;
    margin-left: auto;
    margin-right: auto;
}

.map_parent_div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}

.bg-white {
    background: rgba(65, 65, 66, 0.9) !important;
    color: #FFF !important;
}

.text-dark {
    color: white !important;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    padding: 2px;
}

.ol-control.ol-bar .ol-control {
    margin-bottom: 5px;
}

.ol-control button {
    font-size: 1.3em;
    color: white;
    background-color: #5463A3;
    cursor: pointer;
    font-weight: 300;
}

.ol-control button:focus {}


.ol-control:hover {
    background-color: rgba(255, 255, 255, 1);
}

.ol-zoom .ol-zoom-in {
    border-radius: 2px 2px 0 0;
}

.ol-zoom {
    top: 3em;
    left: .5em;
}

.ol-zoom-all {
    top: 4.5em;
    left: .5em;
}

.ol-layerswitcher>button {
    float: right;
    z-index: 10;
    position: relative;
    font-size: 1.7em;
    -webkit-mask-image: url(icons/layers.svg);
    mask-image: url(icons/layers.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.1em;
    height: 1.1em;
}


.ol-layerswitcher>button:before {
    background: unset;
    top: .32em;
    left: .34em;
    box-shadow: unset;
}

.ol-layerswitcher>button:after {
    top: .22em;
    left: .34em;
    background: unset;
    background-image: unset;
}

.ol-layerswitcher-image li.ol-layer-hidden {
    opacity: 1;
}

.ol-layerswitcher.ol-forceopen .panel {
    background-color: unset;
    font-family: Arial, Helvetica, sans-serif;
}

.ol-layerswitcher-image.ol-collapsed button {
    display: none;
}

.ol-control.ol-layerswitcher-image.ol-collapsed {
    top: unset;
}

.ol-control.ol-layerswitcher-image {
    position: absolute;
    left: 0.5em;
    right: unset;
    top: unset;
    text-align: left;
    bottom: 2.5em;
    transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
}

.ol-layerswitcher .layerup {
    opacity: 1;
    cursor: grab;
}

.ol-layerswitcher-buttons>div {
    width: 2em;
    height: 2em;
}

.layerup {
    background-color: #c8b586;
    -webkit-mask-image: url(icons/drag_up_down.png);
    mask-image: url(icons/drag_up_down.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.9em;
    height: 1.9em;
    position: absolute;
    left: 0;
}

.ol-layerswitcher .layerExtent {
    opacity: 1;
}

.layerExtent {
    background-color: #c8b586;
    -webkit-mask-image: url(icons/baseline-fullscreen-24px.svg);
    mask-image: url(icons/baseline-fullscreen-24px.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.9em;
    height: 1.9em;
    position: absolute;
    left: 0;
}

.ol-layerswitcher .layerswitcher-opacity {
    position: relative;
    border: 1px solid #369;
    height: 3px;
    width: 15em;
    margin: 0px 1em 10px 7px;
    box-sizing: border-box;
    border-radius: 3px;
    background: #69c;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 60, 136, 0)), to(rgba(0, 60, 136, 0.6)));
    background: -webkit-linear-gradient(left, rgba(0, 60, 136, 0), rgba(0, 60, 136, 0.6));
    background: linear-gradient(to right, rgba(0, 60, 136, 0), rgba(0, 60, 136, 0.6));
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.ol-layerswitcher .panel li {
    width: 17em;
}

.ol-layerswitcher .panel li label {
    max-width: unset;
    width: 12em;
}

.ol-layerswitcher .layerswitcher-legend {
    min-width: 2.5em;
    max-width: 2.5em;
    /* display: block;
    margin: 10px auto auto auto; */
}

.ol-layerswitcher hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}


.ol-scale-line {
    left: 0.5em;
}

.map .ol-custom-overviewmap,
.map .ol-custom-overviewmap.ol-uncollapsible {
    bottom: 2em;
    left: auto;
    right: 0.5em;
    top: auto;
}

.ol-custom-overviewmap:not(.ol-collapsed) {
    border: 1px solid black;
}

.ol-custom-overviewmap .ol-overviewmap-map {
    border: none;
    width: 300px;
}

.ol-custom-overviewmap .ol-overviewmap-box {
    border: 2px solid red;
}

.ol-custom-overviewmap:not(.ol-collapsed) button {
    bottom: 0em;
    left: auto;
    right: 0;
    top: auto;
}

.ol-control.ol-bar.ol-top.ol-left, .ol-control.ol-bar.ol-top.ol-right {
    top: 9em;
    -webkit-transform: none;
    transform: none;
}

.map-icon {
    background-color: white;
    -webkit-mask-image: url(icons/earth.png);
    mask-image: url(icons/earth.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.25em;
    height: 1.25em;
    position: absolute;
    left: 3px;
    top: 1px;
    right: 0;
}

.intro-icon {
    background-color: white;
    -webkit-mask-image: url(icons/intro.png);
    mask-image: url(icons/intro.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.25em;
    height: 1.25em;
    position: absolute;
    left: 3px;
    top: 1px;
    right: 0;
}

.search-poi {
    background-color: white;
    -webkit-mask-image: url(icons/binoculars.svg);
    mask-image: url(icons/binoculars.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1em;
    height: 1em;
    position: absolute;
    left: 5px;
    top: 5px;
    right: 0;
}

.about {
    background-color: white;
    -webkit-mask-image: url(icons/about.svg);
    mask-image: url(icons/about.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1em;
    height: 1em;
    position: absolute;
    left: 5px;
    top: 5px;
    right: 0;
}



.draw-draw {
    background-color: white;
    -webkit-mask-image: url(icons/draw.svg);
    mask-image: url(icons/draw.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1em;
    height: 1em;
    position: absolute;
    left: 5px;
    top: 5px;
    right: 0;
}

.draw-point {
    background-color: white;
    -webkit-mask-image: url(icons/point.svg);
    mask-image: url(icons/point.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.5em;
    height: 1.5em;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}

.draw-line {
    background-color: white;
    -webkit-mask-image: url(icons/line.svg);
    mask-image: url(icons/line.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.5em;
    height: 1.5em;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}

.draw-polygon {
    background-color: white;
    -webkit-mask-image: url(icons/polygon.svg);
    mask-image: url(icons/polygon.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.0em;
    height: 1.0em;
    position: absolute;
    left: 5px;
    top: 5px;
    right: 0;
}

.draw-delete {
    background-color: white;
    -webkit-mask-image: url(icons/delete.svg);
    mask-image: url(icons/delete.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.0em;
    height: 1.0em;
    position: absolute;
    left: 5px;
    top: 5px;
    right: 0;
}

.measure-icon {
    position: absolute;
    top: 6px;
    left: 4px;
    width: 80%;
    height: 80%;
    background-color: white;
    -webkit-mask-image: url('icons/measure_white.png');
    mask-image: url('icons/measure_white.png');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain
}

.measure-icon-line {
    position: absolute;
    top: 6px;
    left: 4px;
    width: 80%;
    height: 80%;
    background-color: white;
    -webkit-mask-image: url('icons/baseline-timeline-24px.svg');
    mask-image: url('icons/baseline-timeline-24px.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain
}

.measure-icon-polygon {
    position: absolute;
    top: 3px;
    left: 2px;
    width: 80%;
    height: 80%;
    background-color: white;
    -webkit-mask-image: url('icons/baseline-signal_cellular_null-24px.svg');
    mask-image: url('icons/baseline-signal_cellular_null-24px.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain
}

.ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
}

.ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
}

.ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.ol-tooltip-static:before {
    border-top-color: #ffcc33;
}

.print-icon {
    position: absolute;
    top: 1px;
    left: 4px;
    width: 80%;
    height: 80%;
    background-color: white;
    -webkit-mask-image: url('icons/print.svg');
    mask-image: url('icons/print.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain
}

.ol-zoom-all {
    top: 7.5em;
    left: .5em;
}

.zoom-all-icon {
    background-color: white;
    -webkit-mask-image: url(icons/zoom-all-icon.svg);
    mask-image: url(icons/zoom-all-icon.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 2em;
    height: 2em;
    position: absolute;
    left: 2px;
    top: 0px;
    right: 0;
}

.directions-icon {
    position: absolute;
    top: 1px;
    left: 4px;
    width: 80%;
    height: 80%;
    background-color: white;
    -webkit-mask-image: url('icons/directions-icon.svg');
    mask-image: url('icons/directions-icon.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain
}

.ol-progress {
    position: absolute;
    left: 30%;
    bottom: 3em;
    width: 30%;
    height: 10px;
    opacity: 1;
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: rgba(65, 65, 66, 0.9);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.progress-bar-info {
    background-color: rgba(65, 65, 66, 0.9);
}


.map-info-icon {
    position: absolute;
    top: 1px;
    left: 4px;
    width: 80%;
    height: 80%;
    background-color: white;
    -webkit-mask-image: url('icons/map-info-icon.svg');
    mask-image: url('icons/map-info-icon.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain
}

.modal-dialog {
    height: 90%;
    width: 50%;
}

.modal-footer {
    display: unset;
}

.intro-modal {
    height: 90%;
    width: 60%;
}

.custom-mouse-position {
    position: absolute;
    bottom: 1em;
    left: 45%;
    color: white;
    text-shadow: 2px 2px #000000;
}

.spinner {
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2rem;
    margin-left: -2rem;
}

.page-item.active .page-link {
    background-color: rgba(0, 60, 136, 0.7);
    border-color: rgba(0, 60, 136, 0.7);
}

.ol-control.ol-layerswitcher-popup {
    top: 8em;
    font-size: smaller;
}

.ol-layerswitcher-popup.ol-collapsed .panel {
    display: block;
}

.ol-layerswitcher-popup.ol-collapsed .panel>li:nth-child(2) {
    display: block;
}

.ol-layerswitcher-popup .panel>li:nth-child(2) {
    display: block;
}


.ol-layerswitcher-popup button {
    display: none;
}

.ol-layerswitcher .panel li.ol-layer-hidden {
    opacity: 1;
}

.ol-layerswitcher .layerExtent {
    opacity: 1;
    float: right;
}

.map {
    height: 100%;
    width: 100%;
    background-color: white;
}

.route-div {
    position: absolute;
    bottom: 14.5em;
    left: 0.5em;
    width: 31.5em;
    font-size: xx-small;
    text-align: center;
}

.route-div .place {
    display: inline-flex;
    margin: 10px;
    padding: 10px;
    border: 1px solid #bbb;
    background-color: #eee;
    width: 93%;
}

.route-div .place span {
    font-size: 2.5em;
    text-decoration: none;
    color: #333;
}

.route-div .place-delete {
    background-color: white;
    -webkit-mask-image: url(icons/delete.svg);
    mask-image: url(icons/delete.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.0em;
    height: 1.0em;
}

.route-div .close-panel {
    -webkit-mask-image: url(icons/baseline-expand_more-24px.svg);
    mask-image: url(icons/baseline-expand_more-24px.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 3.0em;
    height: 3.0em;
    position: absolute;
    right: 0;
    top: 0;
}

.route-div .open-panel {
    -webkit-mask-image: url(icons/baseline-expand_less-24px.svg);
    mask-image: url(icons/baseline-expand_less-24px.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 3.0em;
    height: 3.0em;
    position: absolute;
    right: 0;
    top: 0;
}

.route-div .route-create-button {
    width: 8rem;
    height: 2em;
    margin: 0.5em;
    float: left;
}

.route-div .route-delete-button {
    width: 8rem;
    height: 2em;
    margin: 0.5em;
    float: right;
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.card-body {
    padding: unset;
}

.ol-attribution button {
    flex-shrink: 0;
    display: none;
}

.ol-attribution.ol-collapsed ul {
    display: block;
}

.btn-secondary {
    color: #fff;
    background-color: #5463A3;
    border-color: #5463A3;
}

.btn-secondary:hover {
    color: #fff;
    background-color: rgba(0, 60, 136);
    border-color: rgba(0, 60, 136);
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 60, 136);
    border-color: rgba(0, 60, 136);
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    background: #5463A3;
    color: white;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: white;
    text-shadow: unset;
    opacity: 1;
}

.close:hover {
    color: white;
    text-decoration: none;
    text-shadow: unset;
    opacity: 1;
}

.ol-control.ol-bar .ol-toggle.ol-active>button {
    background-color: rgba(0, 60, 136)
}

.ol-control.ol-bar .ol-toggle.ol-active button:hover {
    background-color: rgba(0, 60, 136)
}


.ol-scale-bar {
    display: none;
}

.logos-div {
    bottom: 0;
    right: 0;
}

.ol-touch .ol-control.ol-bar.ol-top.ol-left, .ol-touch .ol-control.ol-bar.ol-top.ol-right {
    top: 9em;
}

.ol-control.ol-layerswitcher {
    max-height: calc(100% - 7em);
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 90%;
        margin: 1.75rem auto;
    }
}

@media (max-width: 1280px) and (max-height: 950px) {}

@media (max-width: 1280px) and (max-height: 800px) {}

@media (width: 1440px) and (height: 900px) {}

@media (min-width: 1920px) and (min-height: 1080px) {}

@media (min-width: 1536px) and (min-height: 864px) {}

@media only screen and (max-width: 600px) {
    .ol-layerswitcher-popup.ol-collapsed .panel {
        display: none;
    }

    .ol-layerswitcher-popup button {
        display: block;
    }

    .ol-custom-overviewmap {
        display: none;
    }

    .ol-attribution {
        display: block;
    }

    .custom-mouse-position {
        display: none;
    }

    .ol-scale-bar {
        display: none;
    }

    .ol-control.ol-layerswitcher-image {
        bottom: 0.5em;
        width: 150px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-dialog {
        height: 90%;
        width: 95%;
    }

    .route-div {
        bottom: 10em;
        left: 0;
        width: 100%;
    }

    .route-div .route-create-button {
        width: 10rem;
    }

    .route-div .route-delete-button {
        width: 10rem;
    }

    .menu_div {
        display: none;
    }

    .ol-control.ol-layerswitcher {
        top: 0.5em;
    }

    .ol-zoom {
        top: 0.5em;
    }

    .ol-control.ol-bar.ol-top.ol-left, .ol-control.ol-bar.ol-top.ol-right {
        top: 6.5em;
    }

    .ol-touch .ol-control.ol-bar.ol-top.ol-left, .ol-touch .ol-control.ol-bar.ol-top.ol-right {
        top: 6.5em;
    }

}